import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  Divider,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import Calendar from 'react-calendar';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
  Add as AddIcon,
} from '@mui/icons-material';

const initialBOOKINGSs = [
  {
    id: 1,
    title: 'BOOKINGS 1',
    start: new Date(2024, 6, 20, 10, 0),
    end: new Date(2024, 6, 20, 12, 0),
    BOOKINGSType: 'Meeting',
    organizer: 'John Doe',
    status: 'Upcoming',
  },
  {
    id: 2,
    title: 'BOOKINGS 2',
    start: new Date(2024, 6, 21, 14, 0),
    end: new Date(2024, 6, 21, 16, 0),
    BOOKINGSType: 'Conference',
    organizer: 'Jane Smith',
    status: 'Future',
  },
  // Add more BOOKINGSs here
];

export default function BOOKINGSs() {
  const [BOOKINGSs, setBOOKINGSs] = useState(initialBOOKINGSs);
  const [filteredBOOKINGSs, setFilteredBOOKINGSs] = useState(initialBOOKINGSs);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [searchTerm, setSearchTerm] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedBOOKINGSId, setSelectedBOOKINGSId] = useState(null);
  const [newBOOKINGS, setNewBOOKINGS] = useState({
    title: '',
    BOOKINGSType: '',
    date: '',
    startTime: '',
    endTime: '',
    organizer: '',
    paymentStatus: '',
  });
  const [openActionModal, setOpenActionModal] = useState(false); // New state for action modal

  useEffect(() => {
    filterBOOKINGSs();
  }, [selectedDate, searchTerm, BOOKINGSs]);

  const handleDateClick = (date) => {
    setSelectedDate(date);
    setOpenActionModal(true); // Open the action modal when a date is clicked
  };

  const handleInputChange = (BOOKINGS) => {
    const { name, value } = BOOKINGS.target;
    setNewBOOKINGS((prevBOOKINGS) => ({ ...prevBOOKINGS, [name]: value }));
  };

  const calculateTotalHours = (startTime, endTime) => {
    const start = moment(startTime, 'HH:mm');
    const end = moment(endTime, 'HH:mm');
    return end.diff(start, 'hours', true).toFixed(2);
  };

  const handleAddBOOKINGS = () => {
    const { title, date, startTime, endTime, BOOKINGSType, organizer, paymentStatus } = newBOOKINGS;
    const start = new Date(`${date}T${startTime}`);
    const end = new Date(`${date}T${endTime}`);
    const totalHours = calculateTotalHours(startTime, endTime);
    const newBOOKINGSToAdd = {
      id: BOOKINGSs.length + 1,
      title,
      start,
      end,
      BOOKINGSType,
      organizer,
      paymentStatus,
      totalHours,
      status: determineStatus(start, end),
    };
    setBOOKINGSs([...BOOKINGSs, newBOOKINGSToAdd]);
    setOpen(false);
    filterBOOKINGSs();
  };

  const determineStatus = (start, end) => {
    const now = new Date();
    if (end < now) return 'Past';
    if (start > now) return 'Future';
    return 'Upcoming';
  };

  const handleDelete = (id) => {
    setSelectedBOOKINGSId(id);
    setOpenDelete(true);
  };

  const confirmDelete = () => {
    setBOOKINGSs(BOOKINGSs.filter((BOOKINGS) => BOOKINGS.id !== selectedBOOKINGSId));
    setOpenDelete(false);
    filterBOOKINGSs();
  };

  const handleSearchChange = (BOOKINGS) => {
    setSearchTerm(BOOKINGS.target.value);
    filterBOOKINGSs();
  };

  const handleChangePage = (BOOKINGS, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (BOOKINGS) => {
    setRowsPerPage(parseInt(BOOKINGS.target.value, 10));
    setPage(0);
  };

  const filterBOOKINGSs = () => {
    const filtered = BOOKINGSs.filter((BOOKINGS) => {
      const BOOKINGSDate = new Date(BOOKINGS.start).toDateString();
      const selectedDateStr = new Date(selectedDate).toDateString();
      return (
        BOOKINGSDate === selectedDateStr &&
        (BOOKINGS.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
         BOOKINGS.organizer.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    });
    setFilteredBOOKINGSs(filtered);
  };

  const rows = filteredBOOKINGSs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const getStatusCount = (status) => {
    return BOOKINGSs.filter((BOOKINGS) => BOOKINGS.status === status).length;
  };

  const handleModalAction = (action) => {
    setOpenActionModal(false);
    if (action === 'filter') {
      // Implement the filtering functionality here if needed
    } else if (action === 'newBOOKINGS') {
      setOpen(true);
    }
  };

  return (
    <div className="BOOKINGSs-container">
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={3}>
          <Card>
            <CardHeader title="Upcoming BOOKINGSs" />
            <CardContent>
              <Typography variant="h6">{getStatusCount('Upcoming')}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardHeader title="Future BOOKINGSs" />
            <CardContent>
              <Typography variant="h6">{getStatusCount('Future')}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardHeader title="Canceled BOOKINGSs" />
            <CardContent>
              <Typography variant="h6">{getStatusCount('Canceled')}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardHeader title="Past BOOKINGSs" />
            <CardContent>
              <Typography variant="h6">{getStatusCount('Past')}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Card>
            <CardHeader
              title={`BOOKINGSs on ${moment(selectedDate).format('MMMM Do YYYY')}`}
              action={
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => setOpen(true)}
                  style={{ margin: '8px' }}
                >
                  Add BOOKINGS
                </Button>
              }
            />
            <Divider />
            <CardContent>
              <TextField
                label="Search BOOKINGSs"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                style={{ marginBottom: '16px' }}
              />
              {rows.length > 0 ? (
                rows.map((BOOKINGS) => (
                  <Typography key={BOOKINGS.id} className="BOOKINGS-item">
                    {BOOKINGS.title} ({moment(BOOKINGS.start).format('hh:mm A')} - {moment(BOOKINGS.end).format('hh:mm A')})
                    <IconButton onClick={() => handleDelete(BOOKINGS.id)} style={{ marginLeft: '10px' }}>
                      <DeleteIcon />
                    </IconButton>
                  </Typography>
                ))
              ) : (
                <Typography>No BOOKINGSs on this day.</Typography>
              )}
            </CardContent>
          </Card>

          <TableContainer component={Paper} style={{ marginTop: '20px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>BOOKINGS Name</TableCell>
                  <TableCell>BOOKINGS Type</TableCell>
                  <TableCell>BOOKINGS Date</TableCell>
                  <TableCell>BOOKINGS Start Time</TableCell>
                  <TableCell>BOOKINGS End Time</TableCell>
                  <TableCell>BOOKINGS Total Hours</TableCell>
                  <TableCell>Payment Status</TableCell>
                  <TableCell>BOOKINGS Organizer</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((BOOKINGS) => (
                  <TableRow key={BOOKINGS.id}>
                    <TableCell>{BOOKINGS.title}</TableCell>
                    <TableCell>{BOOKINGS.BOOKINGSType}</TableCell>
                    <TableCell>{moment(BOOKINGS.start).format('YYYY-MM-DD')}</TableCell>
                    <TableCell>{moment(BOOKINGS.start).format('HH:mm')}</TableCell>
                    <TableCell>{moment(BOOKINGS.end).format('HH:mm')}</TableCell>
                    <TableCell>{BOOKINGS.totalHours} hours</TableCell>
                    <TableCell>{BOOKINGS.paymentStatus}</TableCell>
                    <TableCell>{BOOKINGS.organizer}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDelete(BOOKINGS.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredBOOKINGSs.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader title="Calendar" />
            <CardContent>
              <Calendar
                onClickDay={handleDateClick}
                value={selectedDate}
                minDetail="month"
                next2Label={null}
                prev2Label={null}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add New BOOKINGS</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            name="title"
            value={newBOOKINGS.title}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="BOOKINGS Type"
            name="BOOKINGSType"
            value={newBOOKINGS.BOOKINGSType}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Date"
            type="date"
            name="date"
            value={newBOOKINGS.date}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            label="Start Time"
            type="time"
            name="startTime"
            value={newBOOKINGS.startTime}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            label="End Time"
            type="time"
            name="endTime"
            value={newBOOKINGS.endTime}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            label="Organizer"
            name="organizer"
            value={newBOOKINGS.organizer}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Payment Status"
            name="paymentStatus"
            value={newBOOKINGS.paymentStatus}
            onChange={handleInputChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddBOOKINGS} color="primary">
            Add BOOKINGS
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete this BOOKINGS?</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openActionModal} onClose={() => setOpenActionModal(false)}>
        <DialogTitle>Actions</DialogTitle>
        <DialogContent>
          <Button onClick={() => handleModalAction('filter')} color="primary">
            Filter BOOKINGSs
          </Button>
          <Button onClick={() => handleModalAction('newBOOKINGS')} color="primary">
            Add New BOOKINGS
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenActionModal(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
