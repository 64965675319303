


import React from 'react';
import ApexCharts from 'react-apexcharts';

const Chart = () => {
  const options = {
    // ... your options
    
  };

  const series = [
    {
      name: 'series1',
      data: [31, 40, 28, 51, 42, 109, 100],
    },
    {
      name: 'series2',
      data: [11, 32, 45, 32, 34, 52, 41],
    },
  ];

  return (
    <div>
      <ApexCharts options={options} series={series} type="area" height={350} />
    </div>
  );
};

export default Chart;
