import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Dashboard.css';
import Chart from '../../components/chart/Chart'; // Adjust the import path as needed


const Dashboard = () => {
  const [totalBudget, setTotalBudget] = useState(0);
  const [totalBudgetNext7Days, setTotalBudgetNext7Days] = useState(0);
  const [dateRange, setDateRange] = useState('Current Month');
  const [customDateRange, setCustomDateRange] = useState([null, null]);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    getTotalBudget();
    get7daysBudget();
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const getTotalBudget = async () => {
    try {
      const response = await fetch('https://backend-lrgj.onrender.com/api/Events/totalBudget');
      if (response.status === 200) {
        const data = await response.json();
        setTotalBudget(data.totalBudget);
      } else {
        console.error('Failed to get total budget');
      }
    } catch (error) {
      console.error('An error occurred:', error.message);
    }
  };

  const get7daysBudget = async () => {
    try {
      const response = await fetch('https://backend-lrgj.onrender.com/api/Events/totalBudgetNext7Days');
      if (response.status === 200) {
        const data = await response.json();
        setTotalBudgetNext7Days(data.totalBudgetNext7Days);
      } else {
        console.error('Failed to get total budget');
      }
    } catch (error) {
      console.error('An error occurred:', error.message);
    }
  };

  const refreshData = () => {
    getTotalBudget();
    get7daysBudget();
  };

  const handleCalendarOpen = () => {
    setCalendarOpen(true);
  };

  const handleCalendarClose = () => {
    setCalendarOpen(false);
  };

  const handleDateChange = (range) => {
    setCustomDateRange(range);
    setCalendarOpen(false);
    // Trigger data fetch based on customDateRange
  };

  return (
    <div className="dashboard">
      <div className="controls">
        <Select
          value={dateRange}
          onChange={(e) => setDateRange(e.target.value)}
          className="date-select"
          displayEmpty
        >
          <MenuItem value="Current Month">Current Month</MenuItem>
          <MenuItem value="Previous Month">Previous Month</MenuItem>
          <MenuItem value="3 Months">3 Months</MenuItem>
          <MenuItem value="6 Months">6 Months</MenuItem>
          <MenuItem value="Current Year">Current Year</MenuItem>
          <MenuItem value="Previous Year">Previous Year</MenuItem>
          <MenuItem value="Custom Date Range">
            <CalendarMonthIcon
              className="calendar-icon"
              onClick={handleCalendarOpen}
            />
            Custom Date Range
          </MenuItem>
        </Select>

        {/* <Typography variant="h6" className="current-time">
          {currentTime.toLocaleTimeString()}
        </Typography> */}

        <Button
          variant="contained"
          color="primary"
          startIcon={<RefreshIcon />}
          onClick={refreshData}
          className="refresh-button"
        >
          Refresh
        </Button>
      </div>

      <Grid container spacing={3} className="metrics-grid">
        <Grid item xs={12} sm={6} md={3}>
          <Paper className="card">
            <Typography variant="h6" className="card-title">Upcoming Bookings</Typography>
            <Typography className="card-value">29</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper className="card">
            <Typography variant="h6" className="card-title">New Volunteers</Typography>
            <Typography className="card-value">33</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper className="card">
            <Typography variant="h6" className="card-title">Recent Hall Bookings</Typography>
            <Typography className="card-value">17</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper className="card">
            <Typography variant="h6" className="card-title">New Event</Typography>
            <Typography className="card-value">17</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper className="card">
            <Typography variant="h6" className="card-title">Pending Events</Typography>
            <Typography className="card-value">17</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper className="card">
            <Typography variant="h6" className="card-title">RECENT DONATIONS</Typography>
            <Typography className="card-value">22</Typography>
          </Paper>
        </Grid>
      </Grid>
      

      <div className="charts-section">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Paper className="small-chart" onClick={() => window.location.href = '/events'}>
              <Typography variant="h6">Events</Typography>
              <Chart type="events" />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper className="small-chart" onClick={() => window.location.href = '/volunteers'}>
              <Typography variant="h6">Volunteers</Typography>
              <Chart type="volunteers" />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper className="small-chart" onClick={() => window.location.href = '/bookings'}>
              <Typography variant="h6">Bookings</Typography>
              <Chart type="bookings" />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper className="small-chart" onClick={() => window.location.href = '/donations'}>
              <Typography variant="h6">Donations</Typography>
              <Chart type="donations" />
            </Paper>
          </Grid>
        </Grid>
      </div>

      <Modal open={calendarOpen} onClose={handleCalendarClose}>
        <Box className="calendar-modal">
          <Calendar
            selectRange
            value={customDateRange}
            onChange={handleDateChange}
            className="calendar"
          />
        </Box>
      </Modal>
    </div>
  );
};

export default Dashboard;
