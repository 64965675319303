import React, { useState } from 'react';
import { Container, Paper, Typography, TextField, Button, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { auth } from '../firebase/firebaseConfig';
import { sendPasswordResetEmail } from 'firebase/auth';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.error('Please enter your email address');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success('Password reset email sent! Please check your inbox.');
      navigate('/login'); // Redirect to login page after email is sent
    } catch (error) {
      toast.error('Failed to send reset email: ' + error.message);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: '#F0F0F0', // Light background for contrast
        padding: 2,
      }}
    >
      <Paper
        elevation={6}
        sx={{
          p: 4,
          bgcolor: '#FFFFFF', // White background for the form
          color: '#333333',
          borderRadius: 8,
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          textAlign: 'center',
          width: '100%',
          maxWidth: 400,
        }}
      >
        <Typography component="h1" variant="h4" sx={{ mb: 3, fontWeight: 'bold', color: '#333333' }}>
          Forgot Password
        </Typography>
        <form onSubmit={handleResetPassword} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                borderRadius: 8,
                backgroundColor: '#F9F9F9', // Light grey background for input
                color: '#333333',
              },
              '& .MuiInputLabel-root': {
                color: '#888888',
              },
              '& .MuiInputBase-input': {
                color: '#333333',
              },
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
              borderRadius: 8,
              backgroundColor: '#007BFF', // Primary blue color
              '&:hover': {
                backgroundColor: '#0056b3',
              },
            }}
          >
            Send Reset Link
          </Button>
          <Link
            href="/login"
            variant="body2"
            sx={{
              display: 'block',
              mt: 2,
              color: '#007BFF',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Remembered your password? Login
          </Link>
        </form>
      </Paper>
    </Container>
  );
};

export default ForgotPassword;
