import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  Divider,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import Calendar from 'react-calendar';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
  Add as AddIcon,
} from '@mui/icons-material';

const initialEvents = [
  {
    id: 1,
    title: 'Event 1',
    start: new Date(2024, 6, 20, 10, 0),
    end: new Date(2024, 6, 20, 12, 0),
    eventType: 'Meeting',
    organizer: 'John Doe',
    status: 'Upcoming',
  },
  {
    id: 2,
    title: 'Event 2',
    start: new Date(2024, 6, 21, 14, 0),
    end: new Date(2024, 6, 21, 16, 0),
    eventType: 'Conference',
    organizer: 'Jane Smith',
    status: 'Future',
  },
  // Add more events here
];

export default function Events() {
  const [events, setEvents] = useState(initialEvents);
  const [filteredEvents, setFilteredEvents] = useState(initialEvents);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [searchTerm, setSearchTerm] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [newEvent, setNewEvent] = useState({
    title: '',
    eventType: '',
    date: '',
    startTime: '',
    endTime: '',
    organizer: '',
    paymentStatus: '',
  });
  const [openActionModal, setOpenActionModal] = useState(false); // New state for action modal

  useEffect(() => {
    filterEvents();
  }, [selectedDate, searchTerm, events]);

  const handleDateClick = (date) => {
    setSelectedDate(date);
    setOpenActionModal(true); // Open the action modal when a date is clicked
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewEvent((prevEvent) => ({ ...prevEvent, [name]: value }));
  };

  const calculateTotalHours = (startTime, endTime) => {
    const start = moment(startTime, 'HH:mm');
    const end = moment(endTime, 'HH:mm');
    return end.diff(start, 'hours', true).toFixed(2);
  };

  const handleAddEvent = () => {
    const { title, date, startTime, endTime, eventType, organizer, paymentStatus } = newEvent;
    const start = new Date(`${date}T${startTime}`);
    const end = new Date(`${date}T${endTime}`);
    const totalHours = calculateTotalHours(startTime, endTime);
    const newEventToAdd = {
      id: events.length + 1,
      title,
      start,
      end,
      eventType,
      organizer,
      paymentStatus,
      totalHours,
      status: determineStatus(start, end),
    };
    setEvents([...events, newEventToAdd]);
    setOpen(false);
    filterEvents();
  };

  const determineStatus = (start, end) => {
    const now = new Date();
    if (end < now) return 'Past';
    if (start > now) return 'Future';
    return 'Upcoming';
  };

  const handleDelete = (id) => {
    setSelectedEventId(id);
    setOpenDelete(true);
  };

  const confirmDelete = () => {
    setEvents(events.filter((event) => event.id !== selectedEventId));
    setOpenDelete(false);
    filterEvents();
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterEvents();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterEvents = () => {
    const filtered = events.filter((event) => {
      const eventDate = new Date(event.start).toDateString();
      const selectedDateStr = new Date(selectedDate).toDateString();
      return (
        eventDate === selectedDateStr &&
        (event.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
         event.organizer.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    });
    setFilteredEvents(filtered);
  };

  const rows = filteredEvents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const getStatusCount = (status) => {
    return events.filter((event) => event.status === status).length;
  };

  const handleModalAction = (action) => {
    setOpenActionModal(false);
    if (action === 'filter') {
      // Implement the filtering functionality here if needed
    } else if (action === 'newEvent') {
      setOpen(true);
    }
  };

  return (
    <div className="events-container">
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={3}>
          <Card>
            <CardHeader title="Upcoming Events" />
            <CardContent>
              <Typography variant="h6">{getStatusCount('Upcoming')}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardHeader title="Future Events" />
            <CardContent>
              <Typography variant="h6">{getStatusCount('Future')}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardHeader title="Canceled Events" />
            <CardContent>
              <Typography variant="h6">{getStatusCount('Canceled')}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardHeader title="Past Events" />
            <CardContent>
              <Typography variant="h6">{getStatusCount('Past')}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Card>
            <CardHeader
              title={`Events on ${moment(selectedDate).format('MMMM Do YYYY')}`}
              action={
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => setOpen(true)}
                  style={{ margin: '8px' }}
                >
                  Add Event
                </Button>
              }
            />
            <Divider />
            <CardContent>
              <TextField
                label="Search Events"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                style={{ marginBottom: '16px' }}
              />
              {rows.length > 0 ? (
                rows.map((event) => (
                  <Typography key={event.id} className="event-item">
                    {event.title} ({moment(event.start).format('hh:mm A')} - {moment(event.end).format('hh:mm A')})
                    <IconButton onClick={() => handleDelete(event.id)} style={{ marginLeft: '10px' }}>
                      <DeleteIcon />
                    </IconButton>
                  </Typography>
                ))
              ) : (
                <Typography>No events on this day.</Typography>
              )}
            </CardContent>
          </Card>

          <TableContainer component={Paper} style={{ marginTop: '20px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Event Name</TableCell>
                  <TableCell>Event Type</TableCell>
                  <TableCell>Event Date</TableCell>
                  <TableCell>Event Start Time</TableCell>
                  <TableCell>Event End Time</TableCell>
                  <TableCell>Event Total Hours</TableCell>
                  <TableCell>Payment Status</TableCell>
                  <TableCell>Event Organizer</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((event) => (
                  <TableRow key={event.id}>
                    <TableCell>{event.title}</TableCell>
                    <TableCell>{event.eventType}</TableCell>
                    <TableCell>{moment(event.start).format('YYYY-MM-DD')}</TableCell>
                    <TableCell>{moment(event.start).format('HH:mm')}</TableCell>
                    <TableCell>{moment(event.end).format('HH:mm')}</TableCell>
                    <TableCell>{event.totalHours} hours</TableCell>
                    <TableCell>{event.paymentStatus}</TableCell>
                    <TableCell>{event.organizer}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDelete(event.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredEvents.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader title="Calendar" />
            <CardContent>
              <Calendar
                onClickDay={handleDateClick}
                value={selectedDate}
                minDetail="month"
                next2Label={null}
                prev2Label={null}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add New Event</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            name="title"
            value={newEvent.title}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Event Type"
            name="eventType"
            value={newEvent.eventType}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Date"
            type="date"
            name="date"
            value={newEvent.date}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            label="Start Time"
            type="time"
            name="startTime"
            value={newEvent.startTime}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            label="End Time"
            type="time"
            name="endTime"
            value={newEvent.endTime}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            label="Organizer"
            name="organizer"
            value={newEvent.organizer}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Payment Status"
            name="paymentStatus"
            value={newEvent.paymentStatus}
            onChange={handleInputChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddEvent} color="primary">
            Add Event
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete this event?</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openActionModal} onClose={() => setOpenActionModal(false)}>
        <DialogTitle>Actions</DialogTitle>
        <DialogContent>
          <Button onClick={() => handleModalAction('filter')} color="primary">
            Filter Events
          </Button>
          <Button onClick={() => handleModalAction('newEvent')} color="primary">
            Add New Event
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenActionModal(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
