
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const API_URL = 'http://localhost:4000/api/users/user/';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14, 
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function CustomizedTables() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchText, setSearchText] = React.useState('');
  const [rows, setRows] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API and set the rows state
    const fetchData = async () => {
      try {
        const response = await fetch(API_URL);
        if (response.status === 200) {
          const data = await response.json();
          setRows(data);
        } else {
          console.error('API request failed');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  // const handleDeleteRow = (name) => {
  //   fetch(`${API_URL}${name}`, {
  //     method: 'DELETE',
  //   })
  //     .then(async (response) => {
  //       if (response.status === 200) {
  //         // Remove the deleted row from the local state
  //         const updatedRows = rows.filter((row) => row.name !== name);
  //         setRows(updatedRows);
  //         window.location.reload();

  //       } else if (response.status === 404) {
  //         const errorData = await response.json();
  //         console.error(`User not found: ${errorData.error}`);
  //       } else {
  //         console.error('Delete request failed');
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //     });
  // };


  const handleDeleteRow = (userId) => {
    fetch(`${API_URL}${userId}`, {
      method: 'DELETE',
    })
      .then(async (response) => {
        if (response.status === 200) {
          // Remove the deleted row from the local state
          const updatedRows = rows.filter((row) => row._id !== userId);
          setRows(updatedRows);
          window.location.reload();
        } else if (response.status === 404) {
          console.error(`User not found: ${userId}`);
        } else {
          console.error('Delete request failed');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredRows = rows.filter((row) =>
    row.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div>
      <TextField
        label="Search"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        variant="outlined"
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>BDM Name</StyledTableCell>
              <StyledTableCell>BDM Email</StyledTableCell>
              <StyledTableCell>BDM Mobile No</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {filteredRows
  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
  .map((row) => (
    <StyledTableRow key={row._id}>
      <StyledTableCell>{row.name}</StyledTableCell>
      <StyledTableCell>{row.email}</StyledTableCell>
      <StyledTableCell>{row.mobileNumber}</StyledTableCell>
      <StyledTableCell>
      <IconButton
  aria-label="delete"
  onClick={() => handleDeleteRow(row._id)} // Pass the _id as userId
>
  <DeleteIcon />
</IconButton>

      </StyledTableCell>
    </StyledTableRow>
  ))}

          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={filteredRows.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
