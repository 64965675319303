import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // Ensure this file exists and contains necessary styles
import App from './App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for react-toastify
import { BrowserRouter } from 'react-router-dom';

// Ensure the root element exists before calling createRoot
const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <BrowserRouter>
      <App />
      <ToastContainer />
    </BrowserRouter>
  );
} else {
  console.error('Root element not found');
}
