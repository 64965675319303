import React, { useState } from 'react';

// Sample data, replace with actual data fetching
const sampleUsers = [
  { email: 'user1@example.com', permissions: { view: true, edit: false, hide: true } },
  { email: 'user2@example.com', permissions: { view: true, edit: true, hide: false } },
];

const AdminSetup = () => {
  const [users, setUsers] = useState(sampleUsers);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const handleEditPermissions = (user) => {
    setSelectedUser(user);
    setModalOpen(true);
  };

  const handlePermissionChange = (email, newPermissions) => {
    setUsers(users.map(user =>
      user.email === email ? { ...user, permissions: newPermissions } : user
    ));
    setModalOpen(false);
  };

  const handleAddUser = () => {
    if (!newEmail) {
      setEmailError('Email is required');
      return;
    }
    if (users.some(user => user.email === newEmail)) {
      setEmailError('Email already exists');
      return;
    }

    setUsers([...users, { email: newEmail, permissions: { view: false, edit: false, hide: false } }]);
    setNewEmail('');
    setEmailError('');
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = users.filter(user =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="admin-setup">
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by email"
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      <div className="add-user">
        <input
          type="email"
          placeholder="Enter new email"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
        />
        <button onClick={handleAddUser}>Add User</button>
        {emailError && <p className="error">{emailError}</p>}
      </div>
      <table>
        <thead>
          <tr>
            <th>Email</th>
            <th>View</th>
            <th>Edit</th>
            <th>Hide</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.length > 0 ? (
            filteredUsers.map(user => (
              <tr key={user.email}>
                <td>{user.email}</td>
                <td><input type="checkbox" checked={user.permissions.view} readOnly /></td>
                <td><input type="checkbox" checked={user.permissions.edit} readOnly /></td>
                <td><input type="checkbox" checked={user.permissions.hide} readOnly /></td>
                <td>
                  <button onClick={() => handleEditPermissions(user)}>Edit Permissions</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No users found</td>
            </tr>
          )}
        </tbody>
      </table>
      {modalOpen && selectedUser && (
        <div className="modal">
          <h2>Edit Permissions for {selectedUser.email}</h2>
          <div>
            <label>
              View
              <input
                type="checkbox"
                name="view"
                checked={selectedUser.permissions.view}
                onChange={(e) =>
                  setSelectedUser({
                    ...selectedUser,
                    permissions: {
                      ...selectedUser.permissions,
                      view: e.target.checked
                    }
                  })
                }
              />
            </label>
          </div>
          <div>
            <label>
              Edit
              <input
                type="checkbox"
                name="edit"
                checked={selectedUser.permissions.edit}
                onChange={(e) =>
                  setSelectedUser({
                    ...selectedUser,
                    permissions: {
                      ...selectedUser.permissions,
                      edit: e.target.checked
                    }
                  })
                }
              />
            </label>
          </div>
          <div>
            <label>
              Hide
              <input
                type="checkbox"
                name="hide"
                checked={selectedUser.permissions.hide}
                onChange={(e) =>
                  setSelectedUser({
                    ...selectedUser,
                    permissions: {
                      ...selectedUser.permissions,
                      hide: e.target.checked
                    }
                  })
                }
              />
            </label>
          </div>
          <button onClick={() => handlePermissionChange(selectedUser.email, selectedUser.permissions)}>Save</button>
          <button onClick={() => setModalOpen(false)}>Cancel</button>
        </div>
      )}
  <style jsx>{`
  .admin-setup {
    padding: 20px;
    max-width: 900px;
    margin: auto;
    padding-top: 80px; /* Adjust padding to account for the Navbar height */
  }

  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  .search-bar {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .search-bar input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 300px;
  }

  .add-user {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .add-user input {
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 300px;
  }

  .add-user button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .add-user button:hover {
    background-color: #0056b3;
  }

  .error {
    color: red;
    margin-top: 10px;
    text-align: center;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
  }

  button {
    margin: 5px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056b3;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    max-width: 500px;
    width: 100%;
  }

  @media (max-width: 600px) {
    .search-bar, .add-user {
      flex-direction: column;
      align-items: center;
    }

    .search-bar input, .add-user input {
      width: 100%;
      margin-bottom: 10px;
    }

    .modal {
      width: 90%;
      padding: 15px;
    }
  }
`}</style>

    </div>
  );
};

export default AdminSetup;
