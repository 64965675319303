import React, { useState } from 'react';
import { Container, Paper, Typography, TextField, Button, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { auth } from '../firebase/firebaseConfig';
import { createUserWithEmailAndPassword } from 'firebase/auth';

const Signup = () => {
  const navigate = useNavigate();
  const [signupData, setSignupData] = useState({
    name: '',
    email: '',
    mobileNumber: '',
    password: '',
    confirmPassword: '',
  });

  const handleChange = (e) => {
    setSignupData({ ...signupData, [e.target.name]: e.target.value });
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    const { email, password, confirmPassword } = signupData;

    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      localStorage.setItem('isAuthenticated', 'true');
      toast.success('User registered successfully');
      navigate('/login');
    } catch (error) {
      toast.error('Registration failed: ' + error.message);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: '#F5F5F5', // Light background for better contrast
        padding: 2,
      }}
    >
      <Paper
        elevation={6}
        sx={{
          p: 4,
          bgcolor: '#FFFFFF', // White background for the form
          color: '#333333',
          borderRadius: 8,
          boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
          textAlign: 'center',
          width: '100%',
          maxWidth: 400,
        }}
      >
        <Typography component="h1" variant="h4" sx={{ mb: 3, fontWeight: 'bold', color: '#333333' }}>
          Sign Up
        </Typography>
        <form onSubmit={handleSignup}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Name"
            name="name"
            value={signupData.name}
            onChange={handleChange}
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                borderRadius: 8,
                backgroundColor: '#F9F9F9',
                color: '#333333',
              },
              '& .MuiInputLabel-root': {
                color: '#888888',
              },
              '& .MuiInputBase-input': {
                color: '#333333',
              },
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email Address"
            name="email"
            type="email"
            value={signupData.email}
            onChange={handleChange}
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                borderRadius: 8,
                backgroundColor: '#F9F9F9',
                color: '#333333',
              },
              '& .MuiInputLabel-root': {
                color: '#888888',
              },
              '& .MuiInputBase-input': {
                color: '#333333',
              },
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Mobile Number"
            name="mobileNumber"
            value={signupData.mobileNumber}
            onChange={handleChange}
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                borderRadius: 8,
                backgroundColor: '#F9F9F9',
                color: '#333333',
              },
              '& .MuiInputLabel-root': {
                color: '#888888',
              },
              '& .MuiInputBase-input': {
                color: '#333333',
              },
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
            name="password"
            type="password"
            value={signupData.password}
            onChange={handleChange}
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                borderRadius: 8,
                backgroundColor: '#F9F9F9',
                color: '#333333',
              },
              '& .MuiInputLabel-root': {
                color: '#888888',
              },
              '& .MuiInputBase-input': {
                color: '#333333',
              },
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            value={signupData.confirmPassword}
            onChange={handleChange}
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                borderRadius: 8,
                backgroundColor: '#F9F9F9',
                color: '#333333',
              },
              '& .MuiInputLabel-root': {
                color: '#888888',
              },
              '& .MuiInputBase-input': {
                color: '#333333',
              },
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
              borderRadius: 8,
              backgroundColor: '#007BFF', // Primary blue color
              '&:hover': {
                backgroundColor: '#0056b3',
              },
            }}
          >
            Sign Up
          </Button>
          <Link
            href="/login"
            variant="body2"
            sx={{
              display: 'block',
              mt: 2,
              color: '#007BFF',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Already have an account? Login
          </Link>
          <Link
            href="/forgot-password"
            variant="body2"
            sx={{
              display: 'block',
              mt: 1,
              color: '#007BFF',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Forgot Password?
          </Link>
        </form>
      </Paper>
    </Container>
  );
};

export default Signup;
