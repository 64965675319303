import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  Divider,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import Calendar from 'react-calendar';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
  Add as AddIcon,
} from '@mui/icons-material';

const initialVOLUNTEERS = [
  {
    id: 1,
    title: 'VOLUNTEERS 1',
    start: new Date(2024, 6, 20, 10, 0),
    end: new Date(2024, 6, 20, 12, 0),
    VOLUNTEERSType: 'Meeting',
    organizer: 'John Doe',
    status: 'Upcoming',
  },
  {
    id: 2,
    title: 'VOLUNTEERS 2',
    start: new Date(2024, 6, 21, 14, 0),
    end: new Date(2024, 6, 21, 16, 0),
    VOLUNTEERSType: 'Conference',
    organizer: 'Jane Smith',
    status: 'Future',
  },
  // Add more VOLUNTEERS here
];

export default function VOLUNTEERS() {
  const [VOLUNTEERS, setVOLUNTEERS] = useState(initialVOLUNTEERS);
  const [filteredVOLUNTEERS, setFilteredVOLUNTEERS] = useState(initialVOLUNTEERS);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [searchTerm, setSearchTerm] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedVOLUNTEERSId, setSelectedVOLUNTEERSId] = useState(null);
  const [newVOLUNTEERS, setNewVOLUNTEERS] = useState({
    title: '',
    VOLUNTEERSType: '',
    date: '',
    startTime: '',
    endTime: '',
    organizer: '',
    paymentStatus: '',
  });
  const [openActionModal, setOpenActionModal] = useState(false); // New state for action modal

  useEffect(() => {
    filterVOLUNTEERS();
  }, [selectedDate, searchTerm, VOLUNTEERS]);

  const handleDateClick = (date) => {
    setSelectedDate(date);
    setOpenActionModal(true); // Open the action modal when a date is clicked
  };

  const handleInputChange = (VOLUNTEERS) => {
    const { name, value } = VOLUNTEERS.target;
    setNewVOLUNTEERS((prevVOLUNTEERS) => ({ ...prevVOLUNTEERS, [name]: value }));
  };

  const calculateTotalHours = (startTime, endTime) => {
    const start = moment(startTime, 'HH:mm');
    const end = moment(endTime, 'HH:mm');
    return end.diff(start, 'hours', true).toFixed(2);
  };

  const handleAddVOLUNTEERS = () => {
    const { title, date, startTime, endTime, VOLUNTEERSType, organizer, paymentStatus } = newVOLUNTEERS;
    const start = new Date(`${date}T${startTime}`);
    const end = new Date(`${date}T${endTime}`);
    const totalHours = calculateTotalHours(startTime, endTime);
    const newVOLUNTEERSToAdd = {
      id: VOLUNTEERS.length + 1,
      title,
      start,
      end,
      VOLUNTEERSType,
      organizer,
      paymentStatus,
      totalHours,
      status: determineStatus(start, end),
    };
    setVOLUNTEERS([...VOLUNTEERS, newVOLUNTEERSToAdd]);
    setOpen(false);
    filterVOLUNTEERS();
  };

  const determineStatus = (start, end) => {
    const now = new Date();
    if (end < now) return 'Past';
    if (start > now) return 'Future';
    return 'Upcoming';
  };

  const handleDelete = (id) => {
    setSelectedVOLUNTEERSId(id);
    setOpenDelete(true);
  };

  const confirmDelete = () => {
    setVOLUNTEERS(VOLUNTEERS.filter((VOLUNTEERS) => VOLUNTEERS.id !== selectedVOLUNTEERSId));
    setOpenDelete(false);
    filterVOLUNTEERS();
  };

  const handleSearchChange = (VOLUNTEERS) => {
    setSearchTerm(VOLUNTEERS.target.value);
    filterVOLUNTEERS();
  };

  const handleChangePage = (VOLUNTEERS, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (VOLUNTEERS) => {
    setRowsPerPage(parseInt(VOLUNTEERS.target.value, 10));
    setPage(0);
  };

  const filterVOLUNTEERS = () => {
    const filtered = VOLUNTEERS.filter((VOLUNTEERS) => {
      const VOLUNTEERSDate = new Date(VOLUNTEERS.start).toDateString();
      const selectedDateStr = new Date(selectedDate).toDateString();
      return (
        VOLUNTEERSDate === selectedDateStr &&
        (VOLUNTEERS.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
         VOLUNTEERS.organizer.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    });
    setFilteredVOLUNTEERS(filtered);
  };

  const rows = filteredVOLUNTEERS.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const getStatusCount = (status) => {
    return VOLUNTEERS.filter((VOLUNTEERS) => VOLUNTEERS.status === status).length;
  };

  const handleModalAction = (action) => {
    setOpenActionModal(false);
    if (action === 'filter') {
      // Implement the filtering functionality here if needed
    } else if (action === 'newVOLUNTEERS') {
      setOpen(true);
    }
  };

  return (
    <div className="VOLUNTEERS-container">
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={3}>
          <Card>
            <CardHeader title="Upcoming VOLUNTEERS" />
            <CardContent>
              <Typography variant="h6">{getStatusCount('Upcoming')}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardHeader title="Future VOLUNTEERS" />
            <CardContent>
              <Typography variant="h6">{getStatusCount('Future')}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardHeader title="Canceled VOLUNTEERS" />
            <CardContent>
              <Typography variant="h6">{getStatusCount('Canceled')}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardHeader title="Past VOLUNTEERS" />
            <CardContent>
              <Typography variant="h6">{getStatusCount('Past')}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Card>
            <CardHeader
              title={`VOLUNTEERS on ${moment(selectedDate).format('MMMM Do YYYY')}`}
              action={
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => setOpen(true)}
                  style={{ margin: '8px' }}
                >
                  Add VOLUNTEERS
                </Button>
              }
            />
            <Divider />
            <CardContent>
              <TextField
                label="Search VOLUNTEERS"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                style={{ marginBottom: '16px' }}
              />
              {rows.length > 0 ? (
                rows.map((VOLUNTEERS) => (
                  <Typography key={VOLUNTEERS.id} className="VOLUNTEERS-item">
                    {VOLUNTEERS.title} ({moment(VOLUNTEERS.start).format('hh:mm A')} - {moment(VOLUNTEERS.end).format('hh:mm A')})
                    <IconButton onClick={() => handleDelete(VOLUNTEERS.id)} style={{ marginLeft: '10px' }}>
                      <DeleteIcon />
                    </IconButton>
                  </Typography>
                ))
              ) : (
                <Typography>No VOLUNTEERS on this day.</Typography>
              )}
            </CardContent>
          </Card>

          <TableContainer component={Paper} style={{ marginTop: '20px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>VOLUNTEERS Name</TableCell>
                  <TableCell>VOLUNTEERS Type</TableCell>
                  <TableCell>VOLUNTEERS Date</TableCell>
                  <TableCell>VOLUNTEERS Start Time</TableCell>
                  <TableCell>VOLUNTEERS End Time</TableCell>
                  <TableCell>VOLUNTEERS Total Hours</TableCell>
                  <TableCell>Payment Status</TableCell>
                  <TableCell>VOLUNTEERS Organizer</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((VOLUNTEERS) => (
                  <TableRow key={VOLUNTEERS.id}>
                    <TableCell>{VOLUNTEERS.title}</TableCell>
                    <TableCell>{VOLUNTEERS.VOLUNTEERSType}</TableCell>
                    <TableCell>{moment(VOLUNTEERS.start).format('YYYY-MM-DD')}</TableCell>
                    <TableCell>{moment(VOLUNTEERS.start).format('HH:mm')}</TableCell>
                    <TableCell>{moment(VOLUNTEERS.end).format('HH:mm')}</TableCell>
                    <TableCell>{VOLUNTEERS.totalHours} hours</TableCell>
                    <TableCell>{VOLUNTEERS.paymentStatus}</TableCell>
                    <TableCell>{VOLUNTEERS.organizer}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDelete(VOLUNTEERS.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredVOLUNTEERS.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader title="Calendar" />
            <CardContent>
              <Calendar
                onClickDay={handleDateClick}
                value={selectedDate}
                minDetail="month"
                next2Label={null}
                prev2Label={null}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add New VOLUNTEERS</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            name="title"
            value={newVOLUNTEERS.title}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="VOLUNTEERS Type"
            name="VOLUNTEERSType"
            value={newVOLUNTEERS.VOLUNTEERSType}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Date"
            type="date"
            name="date"
            value={newVOLUNTEERS.date}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            label="Start Time"
            type="time"
            name="startTime"
            value={newVOLUNTEERS.startTime}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            label="End Time"
            type="time"
            name="endTime"
            value={newVOLUNTEERS.endTime}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            label="Organizer"
            name="organizer"
            value={newVOLUNTEERS.organizer}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Payment Status"
            name="paymentStatus"
            value={newVOLUNTEERS.paymentStatus}
            onChange={handleInputChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddVOLUNTEERS} color="primary">
            Add VOLUNTEERS
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete this VOLUNTEERS?</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openActionModal} onClose={() => setOpenActionModal(false)}>
        <DialogTitle>Actions</DialogTitle>
        <DialogContent>
          <Button onClick={() => handleModalAction('filter')} color="primary">
            Filter VOLUNTEERS
          </Button>
          <Button onClick={() => handleModalAction('newVOLUNTEERS')} color="primary">
            Add New VOLUNTEERS
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenActionModal(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
