import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { NavLink, useNavigate } from 'react-router-dom';
import { auth } from '../../mainframes/firebase/firebaseConfig';  // Ensure correct path
import { onAuthStateChanged, signOut } from 'firebase/auth';
import "./Navbar.css";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import StyleIcon from '@mui/icons-material/Style';
import PinchIcon from '@mui/icons-material/Pinch';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SearchIcon from '@mui/icons-material/Search';
import AlertIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';

export default function Navbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUsername(user.displayName || 'User');
        setIsAuthenticated(true);
      } else {
        setUsername('');
        setIsAuthenticated(false);
      }
    });
  
    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUsername('');
      setIsAuthenticated(false);
      setLogoutModalOpen(false);
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleLogoutClick = () => {
    setLogoutModalOpen(true);
  };

  const closeLogoutModal = () => {
    setLogoutModalOpen(false);
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="static" className="app-bar">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            className="menu-button"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" className="title">
            <img src={require('../../assets/logo-Bk8i81Uh.png')} height={"75px"} width={"75px"} alt="Logo" />
          </Typography>
          <Box className="search-bar">
            <TextField
              size="small"
              placeholder="Search..."
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              variant="outlined"
              fullWidth
              InputLabelProps={{ style: { color: '#333' } }} // Label color
              sx={{ input: { color: '#333' } }} // Input text color
            />
          </Box>
          <IconButton size="large" edge="end" color="inherit" aria-label="alerts" className="icon-button">
            <AlertIcon />
          </IconButton>
          <IconButton size="large" edge="end" color="inherit" aria-label="settings" className="icon-button">
            <SettingsIcon />
          </IconButton>
          {isAuthenticated ? (
            <Typography variant="h6" component="div" className="username">
              Welcome, {username}
            </Typography>
          ) : (
            <AccountCircleIcon />
          )}
        </Toolbar>
      </AppBar>
      {isAuthenticated && (
        <div className={`main-content ${drawerOpen ? 'shifted' : 'unshifted'}`}>
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            className="drawer"
          >
            <div className="drawer-header">
              <IconButton onClick={toggleDrawer(false)} className="close-button">
                <CloseIcon />
              </IconButton>
              <div className="drawer-logo">
                <img src={require('../../assets/logo-Bk8i81Uh.png')} height={"80px"} width={"80px"} alt="Logo" />
              </div>
            </div>
            <List>
              <NavLink to='/dashboard' className="nav-link">
                <ListItem button className="list-item">
                  <ListItemIcon>
                    <HomeIcon className="icon" />
                  </ListItemIcon>
                  <ListItemText primary="DASHBOARD" />
                </ListItem>
              </NavLink>
              <NavLink to='/Events' className="nav-link">
                <ListItem button className="list-item">
                  <ListItemIcon>
                    <EventIcon className="icon" />
                  </ListItemIcon>
                  <ListItemText primary="EVENTS" />
                </ListItem>
              </NavLink>
              <NavLink to='/VOLUNTEERS' className="nav-link">
                <ListItem button className="list-item">
                  <ListItemIcon>
                    <VolunteerActivismIcon className="icon" />
                  </ListItemIcon>
                  <ListItemText primary="VOLUNTEERS" />
                </ListItem>
              </NavLink>
              <NavLink to='/BOOKINGS' className="nav-link">
                <ListItem button className="list-item">
                  <ListItemIcon>
                    <StyleIcon className="icon" />
                  </ListItemIcon>
                  <ListItemText primary="BOOKINGS" />
                </ListItem>
              </NavLink>
              <NavLink to='/DONATIONS' className="nav-link">
                <ListItem button className="list-item">
                  <ListItemIcon>
                    <PinchIcon className="icon" />
                  </ListItemIcon>
                  <ListItemText primary="DONATIONS" />
                </ListItem>
              </NavLink>
              <NavLink to='/ADMIN' className="nav-link">
                <ListItem button className="list-item">
                  <ListItemIcon>
                    <SensorOccupiedIcon className="icon" />
                  </ListItemIcon>
                  <ListItemText primary="ADMIN/SETUP" />
                </ListItem>
              </NavLink>
              <ListItem button className="list-item" onClick={handleLogoutClick}>
                <ListItemIcon>
                  <ExitToAppIcon className="icon" />
                </ListItemIcon>
                <ListItemText primary="Sign Out" />
              </ListItem>
            </List>
          </Drawer>
        </div>
      )}

      <Modal
        open={logoutModalOpen}
        onClose={closeLogoutModal}
        aria-labelledby="logout-modal-title"
        aria-describedby="logout-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: '#ffffff',
          border: '1px solid #e0e0e0',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="logout-modal-title" variant="h6" component="h2">
            Confirm Logout
          </Typography>
          <Typography id="logout-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to log out?
          </Typography>
          <Button onClick={handleLogout} variant="contained" color="primary" sx={{ mt: 2 }}>
            Yes, Logout
          </Button>
          <Button onClick={closeLogoutModal} variant="outlined" color="secondary" sx={{ mt: 2, ml: 2 }}>
            Cancel
          </Button>
        </Box>
      </Modal>

      <main
        className={`main-content ${drawerOpen ? 'shifted' : 'unshifted'}`}
      >
        {/* Your main content goes here */}
      </main>
    </Box>
  );
}
