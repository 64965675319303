// src/App.jsx
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './mainframes/auth/Login';
import Signup from './mainframes/auth/Signup';
import DataTables from './components/DataTables';
import Navbar from './components/navbar/Navbar';
import Dashboard from './mainframes/all user/Dashboard';
import BDMcontrol from './mainframes/all user/BDMcontrol';
import Events from './components/Events/Events';
import HireDevs from './components/VOLUNTEERS/VOLUNTEERS';
import ADMIN from './mainframes/all user/AllUser';
import ProtectedRoute from './mainframes/ProtectedRoute';
import BOOKINGS from './mainframes/all user/BOOKINGS';
import DONATIONS from './mainframes/all user/DONATIONS';
import ForgotPassword from './mainframes/auth/ForgotPassword';

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/" element={<Navigate to="/login" />} />
        
        {/* Protected Routes */}
        <Route path="/dashboard" element={<ProtectedRoute element={Dashboard} />} />
        <Route path="/table" element={<ProtectedRoute element={DataTables} />} />
        <Route path="/BOOKINGS" element={<ProtectedRoute element={BOOKINGS} />} />
        <Route path="/DONATIONS" element={<ProtectedRoute element={DONATIONS} />} />
        <Route path="/Events" element={<ProtectedRoute element={Events} />} />
        <Route path="/VOLUNTEERS" element={<ProtectedRoute element={HireDevs} />} />
        <Route path="/BDMcontrol" element={<ProtectedRoute element={BDMcontrol} />} />
        <Route path="/ADMIN" element={<ProtectedRoute element={ADMIN} />} />
      </Routes>
    </>
  );
}

export default App;
