// src/firebase/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC6U_RYR3wCD2k0oRyx1MEEreoFVBlbGv8",
  authDomain: "masjidops.firebaseapp.com",
  projectId: "masjidops",
  storageBucket: "masjidops.appspot.com",
  messagingSenderId: "463174374004",
  appId: "1:463174374004:web:4163d7a7524e76e0a6f721",
  measurementId: "G-NGJRTT8KWX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { auth, analytics };
