import React, { useState } from 'react';
import { Container, Paper, Typography, TextField, Button, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/firebaseConfig';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      localStorage.setItem('isAuthenticated', 'true');
      toast.success('Logged in successfully');
      navigate('/dashboard');
    } catch (error) {
      toast.error('Login failed: ' + error.message);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: '#F0F2F5', // Light background color for better contrast
        padding: 2,
        overflow: 'hidden',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          bgcolor: '#FFFFFF',
          color: '#333333',
          borderRadius: 8,
          boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
          textAlign: 'center',
          width: '100%',
          maxWidth: 400,
          border: '1px solid #E0E0E0',
        }}
      >
        <Typography component="h1" variant="h4" sx={{ mb: 3, fontWeight: 'bold', color: '#333333' }}>
          Login
        </Typography>
        <form onSubmit={handleLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                borderRadius: 8,
                backgroundColor: '#F9F9F9',
                color: '#333333',
              },
              '& .MuiInputLabel-root': {
                color: '#888888',
              },
              '& .MuiInputBase-input': {
                color: '#333333',
              },
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                borderRadius: 8,
                backgroundColor: '#F9F9F9',
                color: '#333333',
              },
              '& .MuiInputLabel-root': {
                color: '#888888',
              },
              '& .MuiInputBase-input': {
                color: '#333333',
              },
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
              borderRadius: 8,
              backgroundColor: '#007BFF',
              '&:hover': {
                backgroundColor: '#0056b3',
              },
            }}
          >
            Sign In
          </Button>
          <Link
            href="/signup"
            variant="body2"
            sx={{
              display: 'block',
              mt: 2,
              color: '#007BFF',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Don't have an account? Sign Up
          </Link>
        </form>
      </Paper>
    </Container>
  );
};

export default Login;
