import React, { useEffect, useRef } from 'react';
import { Tabs, Tab, Box, Typography, Paper, Grid, Button, TextField, List, ListItem, ListItemText } from '@mui/material';
import Chart from 'chart.js/auto';

function DONATIONS() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper square>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Overview" />
          <Tab label="Campaigns" />
          <Tab label="Gift Matching" />
          <Tab label="Reports" />
        </Tabs>
      </Paper>
      <Box sx={{ p: 3 }}>
        {value === 0 && <OverviewTab />}
        {value === 1 && <CampaignsTab />}
        {value === 2 && <GiftMatchingTab />}
        {value === 3 && <ReportsTab />}
      </Box>
    </Box>
  );
}

function OverviewTab() {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    const chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June'],
        datasets: [
          {
            label: 'Donations',
            data: [1200, 1500, 1700, 1800, 2200, 2500],
            borderColor: '#3f51b5',
            backgroundColor: 'rgba(63, 81, 181, 0.2)',
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return `Amount: $${tooltipItem.raw}`;
              },
            },
          },
        },
      },
    });

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, []);

  return (
    <div>
      <Typography variant="h6">Donations Overview</Typography>
      <canvas ref={chartRef} style={{ width: '100%', height: '400px' }}></canvas>
    </div>
  );
}

function CampaignsTab() {
  return (
    <div>
      <Typography variant="h6">Campaigns</Typography>
      <Grid container spacing={2}>
        {[...Array(4)].map((_, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h6">Campaign {index + 1}</Typography>
              <Typography>Details about campaign {index + 1}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

function GiftMatchingTab() {
  const [giftMatchingData, setGiftMatchingData] = React.useState([
    { id: 1, donor: 'John Doe', amount: 100, matched: true },
    { id: 2, donor: 'Jane Smith', amount: 200, matched: false },
  ]);
  const [donor, setDonor] = React.useState('');
  const [amount, setAmount] = React.useState('');

  const handleAddMatch = () => {
    setGiftMatchingData([
      ...giftMatchingData,
      { id: giftMatchingData.length + 1, donor, amount: parseFloat(amount), matched: false }
    ]);
    setDonor('');
    setAmount('');
  };

  return (
    <div>
      <Typography variant="h6">Gift Matching</Typography>
      <Box sx={{ mb: 2 }}>
        <TextField
          label="Donor Name"
          variant="outlined"
          value={donor}
          onChange={(e) => setDonor(e.target.value)}
          sx={{ mr: 2 }}
        />
        <TextField
          label="Amount"
          variant="outlined"
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          sx={{ mr: 2 }}
        />
        <Button variant="contained" onClick={handleAddMatch}>Add Match</Button>
      </Box>
      <List>
        {giftMatchingData.map((item) => (
          <ListItem key={item.id}>
            <ListItemText
              primary={`Donor: ${item.donor}`}
              secondary={`Amount: $${item.amount} | Matched: ${item.matched ? 'Yes' : 'No'}`}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

function ReportsTab() {
  const [reportData, setReportData] = React.useState([
    { id: 1, title: 'Monthly Donations', date: '2024-07-01', amount: 1200 },
    { id: 2, title: 'Yearly Campaigns', date: '2024-12-01', amount: 15000 },
  ]);
  const [title, setTitle] = React.useState('');
  const [amount, setAmount] = React.useState('');

  const handleAddReport = () => {
    setReportData([
      ...reportData,
      { id: reportData.length + 1, title, date: new Date().toISOString().split('T')[0], amount: parseFloat(amount) }
    ]);
    setTitle('');
    setAmount('');
  };

  return (
    <div>
      <Typography variant="h6">Reports</Typography>
      <Box sx={{ mb: 2 }}>
        <TextField
          label="Report Title"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ mr: 2 }}
        />
        <TextField
          label="Amount"
          variant="outlined"
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          sx={{ mr: 2 }}
        />
        <Button variant="contained" onClick={handleAddReport}>Add Report</Button>
      </Box>
      <List>
        {reportData.map((item) => (
          <ListItem key={item.id}>
            <ListItemText
              primary={`Title: ${item.title}`}
              secondary={`Date: ${item.date} | Amount: $${item.amount}`}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default DONATIONS;
